import React from 'react';
import Layout from '../components/Layout';
import { useIntl } from 'gatsby-plugin-intl';
import SEO from '../components/seo';
import {
    Banner as PGBanner,
    Title,
    Text,
    Grid,
    Card,
} from '@paygreen/paygreen-ui';

const FailPage = () => {
    const intl = useIntl();

    return (
        <Layout>
            <SEO metaTitle={intl.formatMessage({ id: '500.title' })} />

            <PGBanner gradient="brand" topStyle="none" isFirstContent={true}>
                <Grid alignItems="center" flexWrap="nowrap">
                    <Card blockWidth="lg" hasBackground={false}>
                        <Title htmlTag="h1" colorType="reverse" textSize="xl">
                            {intl.formatMessage({ id: '500.title' })}
                        </Title>
                    </Card>
                </Grid>
            </PGBanner>

            <Grid justifyContent="center">
                <Card hasBackground={false}>
                    <Text align="center" textSize="md">
                        {intl.formatMessage({ id: '500.text' })}
                    </Text>
                </Card>
            </Grid>
        </Layout>
    );
};

export default FailPage;
